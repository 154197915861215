import LaptopImageLM from "../assets/images/AboutImages/about-laptop-LM.png";
import LaptopImageDM from "../assets/images/AboutImages/about-laptop-DM.png";
import { useDarkMode } from "../../../context/DarkModeContext";
export default function Aboutlaptop() {
  const { isDarkMode } = useDarkMode();
  return (
    <div className='laptop'>
      <div className='wrapper laptop-content flex medium-large:flex-row flex-col justify-center medium-large:justify-between'>
        <div className='laptop-content__text md:text-left text-center py-5 medium-large:py-0 max-w-full md:max-w-[565px] self-center'>
          <h3
            className={`md:text-[32px] text-center md:text-left text-[24px] font-bold mb-6 leading-10 ${
              isDarkMode ? "text-dark-mode-mint" : "text-second-blue"
            }`}
          >
            Revolutionizing Recruitment
          </h3>
          <h4
            className={`md:text-[26px] px-2 md:px-0 leading-6 md:leading-8 text-left text-[18px] ${
              isDarkMode ? "text-dark-mode-white" : "text-black"
            }`}
          >
            Bias Zero leverages AI avatars for unbiased, skill-focused hiring.
            Our commitment to fairness and efficiency drives us to continually
            improve and promote equal opportunities.
          </h4>
        </div>
        <div className='laptop-content__image flex self-center'>
          <img
            className='block md:max-w-[600px] very-small:max-w-[400px] ultra-small:max-w-[300px]'
            src={isDarkMode ? LaptopImageDM : LaptopImageLM}
            alt='laptop-interview'
          />
        </div>
      </div>
    </div>
  );
}
