import AboutTitle from "./AboutTitle";
import AboutLaptop from "./AboutLaptop";
import AboutMission from "./AboutMission";
import AboutVision from "./AboutVision";
import AboutResume from "./AboutResume";
import AboutRevolution from "./AboutRevolution";
import { useDarkMode } from "../../../context/DarkModeContext";
import { Pagination, Navigation, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight, faCaretLeft } from "@fortawesome/free-solid-svg-icons";
import "swiper/css";
import "swiper/css/pagination";
export default function AboutUs() {
  const { isDarkMode } = useDarkMode();

  return (
    <article
      className={`about-us ${isDarkMode ? "bg-dark-mode-navy" : "bg-white"}`}
      id='aboutUs'
    >
      <AboutTitle />
      <Swiper
        slidesPerView={1}
        loop={true}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination, Navigation, Autoplay]}
        wrapperClass='md:pt-[40px] pt-[20px]'
        grabCursor={true}
        speed={2000}
        navigation={{
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        }}
      >
        <SwiperSlide>
          <AboutRevolution />
        </SwiperSlide>
        <SwiperSlide>
          <AboutLaptop />
        </SwiperSlide>
        <SwiperSlide>
          <AboutMission />
        </SwiperSlide>
        <SwiperSlide>
          <AboutVision />
        </SwiperSlide>
        <SwiperSlide>
          <AboutResume />
        </SwiperSlide>
        <button
          className={`swiper-button-next bg-transparent text-5xl flex items-center justify-center w-12 h-12 rounded-full absolute top-1/2 transform -translate-y-1/2 right-4 z-10 ${
            isDarkMode ? "text-dark-mode-mint" : "text-second-blue"
          }`}
        >
          <FontAwesomeIcon icon={faCaretRight} />
        </button>
        <button
          className={`swiper-button-prev bg-transparent text-5xl flex items-center justify-center w-12 h-12 rounded-full absolute top-1/2 transform -translate-y-1/2 left-4 z-10 ${
            isDarkMode ? "text-dark-mode-mint" : "text-second-blue"
          }`}
        >
          <FontAwesomeIcon icon={faCaretLeft} />
        </button>
      </Swiper>
    </article>
  );
}
