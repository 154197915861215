import React, { useEffect, useState } from "react";

import { useDarkMode } from "../../../context/DarkModeContext";
import { pricingData } from "../ContactPage/assets/data";
import PriceCard from "./PriceCard";
import ExtendCard from "./ExtendCard";

function Options2() {
  const { isDarkMode } = useDarkMode();
  const [isMobile, setIsMobile] = useState(false);
  const [data, setData] = useState(pricingData);

  const handleClick = (id) => {
    const updateData = data.map((item) =>
      item.id === id ? { ...item, visibility: true } : item
    );
    setData(updateData);
  };

  const handleCloseClick = () => {
    const updateData = data.map((item) => ({ ...item, visibility: false }));
    setData(updateData);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1100);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const findDataExtend = data?.find((item) => item.visibility);
  const backgroundClass = isDarkMode ? "bg-dark-mode-navy" : "bg-white";
  const bgClass = isDarkMode ? "bg-[#0B2045]" : "bg-second-blue";
  return (
    <section className={`${backgroundClass} py-10`} id="pricing">
      <h1
        className={`py-20 font-bold md:text-[34px] ultra-small:text-[30px] medium-large:text-[44px] text-center ${
          isDarkMode ? "text-dark-mode-mint" : "text-second-blue"
        }`}
      >
        Plans & Pricing
      </h1>
      <div className="wrapper-l">
        <div
          className={`${bgClass} grid items-center place-items-center gap-4   ${
            findDataExtend ? "grid-cols-1" : "lg:grid-cols-3"
          } lg:p-8 py-4 rounded-xl`}
        >
          {findDataExtend ? (
            <div className="px-2 w-full md:w-fit animate-expandEnter">
              <ExtendCard
                data={findDataExtend}
                handleClick={handleCloseClick}
                isMobile={isMobile}
              />
            </div>
          ) : (
            pricingData?.map((item) => (
              <div className="w-full px-1.5 medium-small:w-fit" key={item.id}>
                <PriceCard data={item} handleClick={handleClick} />
              </div>
            ))
          )}
        </div>
      </div>
    </section>
  );
}

export default Options2;
