import { useDarkMode } from "../../../context/DarkModeContext";
export default function AboutTitle() {
  const { isDarkMode } = useDarkMode();
  return (
    <h2
      className={`font-bold md:text-[34px] ultra-small:text-[30px] medium-large:text-[44px] text-center ${
        isDarkMode ? "text-dark-mode-mint" : "text-second-blue"
      }`}
    >
      About Us
    </h2>
  );
}
