import { useState, useEffect } from "react";
import Slider from "react-slick";
import { useDarkMode } from "../../../context/DarkModeContext";
import MotionSensorImg from "../assets/images/WhyBiasImages/motion-sensor-image.jpg";
import NervousnessMeterImg from "../assets/images/WhyBiasImages/nervousness-meter-image.jpg";
import ConfidenceMeterImg from "../assets/images/WhyBiasImages/confidence-meter-image.jpg";
import EyeballTrackingImg from "../assets/images/WhyBiasImages/eyeball-tracking-image.jpg";
import FaceDetectorImg from "../assets/images/WhyBiasImages/face-detector-image.jpg";
import KeywordMatchingImg from "../assets/images/WhyBiasImages/keyword-matching-image.jpg";
import AnswerAnalyzerImg from "../assets/images/WhyBiasImages/answer-analyzer-image.jpg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
export default function WhyBiasZero() {
  const whyBiasImages = [
    { id: 0, src: MotionSensorImg, alt: "motion-sensor" },
    { id: 1, src: NervousnessMeterImg, alt: "nervousness-meter" },
    { id: 2, src: ConfidenceMeterImg, alt: "confidence-meter" },
    { id: 3, src: EyeballTrackingImg, alt: "eyeball-tracking" },
    { id: 4, src: FaceDetectorImg, alt: "face-detector" },
    { id: 5, src: KeywordMatchingImg, alt: "keyword-matching" },
    { id: 6, src: AnswerAnalyzerImg, alt: "answer-analyzer" },
  ];
  const features = [
    {
      heading: "Motion Sensor",
      info: "Assess candidate comfort and body language through precise movement tracking.",
      id: 0,
    },
    {
      heading: "Nervousness Meter",
      info: "Detects stress levels with sophisticated physiological analysis.",
      id: 1,
    },
    {
      heading: "Confidence Meter",
      info: "Measure confidence via speech patterns and body language.",
      id: 2,
    },
    {
      heading: "Eyeball Tracking",
      info: "Gauge focus and honesty by monitoring eye movements.",
      id: 3,
    },
    {
      heading: "Face Detector",
      info: "Understand emotions and reactions through facial expression analysis.",
      id: 4,
    },
    {
      heading: "Keyword Matching",
      info: "Ensure candidate responses align perfectly with job requirements.",
      id: 5,
    },
    {
      heading: "Answer Analyzer",
      info: "Evaluate the quality of answers based on context, coherence, and relevance.",
      id: 6,
    },
  ];
  const [content, setContent] = useState(features[0]);
  const [active, setActive] = useState(features[0].id);
  const { isDarkMode } = useDarkMode();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const handleSlideChange = (index) => {
    setContent(features[index]);
    setActive(features[index].id);
  };
  const currentImage = whyBiasImages.find((img) => img.id === content.id);
  const sliderSettings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "20px",
    slidesToShow: 3,
    speed: 0,
    afterChange: (index) => handleSlideChange(index),
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          centerPadding: "0px",
        },
      },
    ],
  };
  return (
    <section
      id='whyBiasZero'
      className={`py-12 ${
        isDarkMode ? "bg-dark-mode-navy text-dark-mode-white" : "bg-white"
      }`}
    >
      <div className='wrapper-l'>
        <h2
          className={`mb-12 font-bold md:text-[34px] ultra-small:text-[30px] medium-large:text-[44px] text-center ${
            isDarkMode ? "text-dark-mode-mint" : "text-second-blue"
          }`}
        >
          Why Bias Zero?
        </h2>
        <div>
          <div
            className={`flex flex-col text-black ${
              isDarkMode && "bg-dark-mode-navy text-dark-mode-white"
            }`}
          >
            {isMobile ? (
              <Slider {...sliderSettings} className='slider-container mb-4'>
                {features.map((feature, index) => (
                  <div key={index} className='text-center'>
                    <button
                      className={`w-min md:text-[22px] ultra-small:text-[18px] max-md:text-xl transition-all duration-300 ease-in-out hover:text-[#1A73E8] ${
                        isDarkMode && "hover:text-dark-mode-mint"
                      } ${
                        active === feature.id
                          ? isDarkMode
                            ? "border-b-4 border-solid border-dark-mode-mint"
                            : "border-second-blue border-b-4 border-solid"
                          : ""
                      }`}
                      onClick={() => handleSlideChange(index)}
                    >
                      {feature.heading}
                    </button>
                  </div>
                ))}
              </Slider>
            ) : (
              <div className='slider-container flex gap-2 justify-evenly items-center flex-wrap mb-4 w-full'>
                {features.map((feature, index) => (
                  <div
                    key={index}
                    className={`${
                      isDarkMode && "bg-dark-mode-navy text-dark-mode-white"
                    }`}
                  >
                    <button
                      className={`w-min md:text-[22px] ultra-small:text-[18px] max-md:text-xl transition-all duration-300 ease-in-out hover:text-[#1A73E8] ${
                        isDarkMode && "hover:text-dark-mode-mint"
                      } ${
                        active === feature.id
                          ? isDarkMode
                            ? "border-b-4 border-solid border-dark-mode-mint"
                            : "border-second-blue border-b-4 border-solid"
                          : ""
                      }`}
                      onClick={() => handleSlideChange(index)}
                    >
                      {feature.heading}
                    </button>
                  </div>
                ))}
              </div>
            )}
            <div
              className={`${
                isDarkMode && "bg-dark-mode-navy text-dark-mode-white"
              } `}
            >
              <div
                className={`flex small-large:justify-center gap-0 md:gap-20 items-center max-md:flex-col md:py-[60px] md:px-[20px] ${
                  content.id === 2 || content.id === 5 ? "flex-row-reverse" : ""
                }`}
              >
                <div className='text-wrapper mb-8 md:mb-0 md:text-left text-center transition-opacity duration-300 ease-in-out'>
                  <h2
                    className={`font-bold text-[22px] md:text-[32px] mb-6 ${
                      isDarkMode ? "text-dark-mode-mint" : "text-second-blue"
                    }`}
                  >
                    {content.heading}
                  </h2>
                  <p className='md:max-w-[541px] md:text-[22px] text-[16px] ultra-small:max-w-full'>
                    {content.info}
                  </p>
                </div>
                <img
                  className={`block rounded-2xl h-auto w-full max-w-[500px] shadow-2xl shadow-gray-600 ${
                    isDarkMode && "shadow-gray-800"
                  }`}
                  src={currentImage.src}
                  alt={currentImage.alt}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
