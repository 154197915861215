import { createContext, useEffect } from "react";
import React, { useState } from "react";

export const CalendarContext = createContext();

export const CalendarContextProvider = ({ children }) => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [reservation, setReservation] = useState({
    status: "initial",
    message: "",
  });

  useEffect(() => {}, []);

  return (
    <CalendarContext.Provider
      value={{ currentDate, setCurrentDate, setReservation, reservation }}
    >
      {children}
    </CalendarContext.Provider>
  );
};
