import AboutResumeLM from "../assets/images/AboutImages/about-resume-LM.png";
import AboutResumeDM from "../assets/images/AboutImages/about-resume-DM.png";
import { useDarkMode } from "./../../../context/DarkModeContext";
export default function AboutResume() {
  const { isDarkMode } = useDarkMode();
  return (
    <div className='wrapper resume-content flex medium-large:flex-row-reverse flex-col justify-center medium-large:justify-between'>
      <div className='resume-content__text text-left leading-8 py-5 medium-large:py-0 max-w-full md:max-w-[565px] self-center'>
        <h3
          className={`md:text-[34px] text-center md:text-left text-[24px] font-bold mb-6 leading-10 ${
            isDarkMode ? "text-dark-mode-mint" : "text-second-blue"
          }`}
        >
          Why Bias-Zero is Unique
        </h3>
        <ul
          className={`md:text-[28px] px-2 md:px-0 flex flex-col gap-y-4 list-disc text-[18px] md:leading-8 leading-6 ${
            isDarkMode ? "text-dark-mode-white" : "text-black"
          }`}
        >
          <li>
            <strong>Unbiased assessments:</strong> Fair evaluations, no biases.
          </li>
          <li>
            <strong>Comprehensive insights:</strong> Detailed feedback.
          </li>
          <li>
            <strong>Efficiency and scalability:</strong> Automated interviews
            for large-scale recruitment.
          </li>
        </ul>
      </div>
      <div className='resume-content__image flex self-center'>
        <img
          className='block md:max-w-[600px] very-small:max-w-[400px] ultra-small:max-w-[300px]'
          src={isDarkMode ? AboutResumeDM : AboutResumeLM}
          alt='resume-interview'
        />
      </div>
    </div>
  );
}
