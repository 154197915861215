import React, { useState, useRef, useEffect } from "react";
import { useDarkMode } from "./../../../context/DarkModeContext";
import HeroTitle from "./HeroTitle";
import DashboardImageLM from "../assets/images/HeroImages/Dashboard_HS_LM.png";
import DashboardImageDM from "../assets/images/HeroImages/Dashboard_HS_DM.jpg";
import WhiteManImage from "../assets/images/HeroImages/man-suit-white-modified.jpg";
import AsianWomanImage from "../assets/images/HeroImages/woman-suit-asian-modified.jpg";
import LatinoManImage from "../assets/images/HeroImages/man-suit-latino-modified.jpg";
import BlackWomanImage from "../assets/images/HeroImages/woman-suit-black-modified.jpg";
import IndianManImage from "../assets/images/HeroImages/man-suit-indian-modified.jpg";
import HeroLinesLM from "../assets/images/HeroImages/hero-lines-LM.png";
import HeroLinesDM from "../assets/images/HeroImages/hero-lines-DM.png";
import HeroBlobLM from "../assets/images/HeroImages/hero-blob-LM.png";
import HeroBlobDM from "../assets/images/HeroImages/hero-blob-DM.png";
export default function Hero() {
  const ref = useRef(null);
  const { isDarkMode } = useDarkMode();
  const [isAvatarSelected, setIsAvatarSelected] = useState(null);
  const toggleSelectedAvatar = (id) => {
    setIsAvatarSelected(id);
  };
  const handleClickOutside = (event) => {
    if (
      ref.current &&
      !ref.current.contains(event.target) &&
      !event.target.classList.contains("avatar-image")
    ) {
      setIsAvatarSelected(null);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const avatarImages = [
    { id: 0, src: LatinoManImage, alt: "Pablo" },
    { id: 1, src: AsianWomanImage, alt: "Yumiko" },
    { id: 2, src: WhiteManImage, alt: "Matteo" },
    { id: 3, src: BlackWomanImage, alt: "Layla" },
    { id: 4, src: IndianManImage, alt: "Arjun" },
  ];
  return (
    <section
      className={`hero-container relative min-h-dvh pt-14 overflow-hidden pb-24 flex md:flex-row ultra-small:flex-col-reverse ${
        isDarkMode ? "bg-dark-mode-navy text-dark-mode-white" : "bg-white"
      }`}
    >
      <div className='absolute md:-right-[140px] -right-[100px] top-96 small-large:top-10'>
        <img
          className='small-large:max-w-full max-w-[300px]'
          src={isDarkMode ? HeroBlobDM : HeroBlobLM}
          alt='hero-blob'
        />
      </div>
      <div className='absolute md:-left-[140px] -left-[100px] top-[450px]'>
        <img
          className='rotate-180 small-large:max-w-[400px] max-w-[200px]'
          src={isDarkMode ? HeroBlobDM : HeroBlobLM}
          alt='hero-blob'
        />
      </div>
      <div className='wrapper-l' ref={ref}>
        <div className='hero-content flex flex-col small-large:flex-row md:gap-x-36 ultra-small:gap-x-0'>
          <div className='hero-dashboard mx-auto medium-large:mx-0 h-full flex gap-y-12 flex-col max-w-custom-max-width text-center md:pt-24 ultra-small:pt-6'>
            <div className='dashboard-content w-full text-left'>
              <HeroTitle />
            </div>
          </div>
          <div className='content-center relative block'>
            <div className='avatars transition-all duration-300 ease-in-out z-20 left-0 small-large:top-[40%] top-[20%] absolute w-full grid grid-cols-5 gap-3 md:justify-center'>
              {avatarImages.map(({ src, alt, id }) => (
                <div
                  className={`avatars flex flex-col ${
                    isAvatarSelected === id
                      ? "scale-[1.6] border-main-blue"
                      : isAvatarSelected !== null
                      ? "blur-[1px] -z-10"
                      : "z-10"
                  }`}
                  key={id}
                >
                  <img
                    src={src}
                    alt={alt}
                    onClick={() => toggleSelectedAvatar(id)}
                    className={`avatar-image max-w-28 transition-all duration-500 ease-in-out border-2 border-solid hover:scale-[1.1] cursor-pointer hover:border-dark-mode-mint ${
                      isDarkMode ? "border-dark-mode-blue" : "border-off-white"
                    } rounded-full shadow-smokey`}
                  />
                </div>
              ))}
            </div>
            <img
              src={isDarkMode ? DashboardImageDM : DashboardImageLM}
              alt='dashboard-sm'
              className='relative md:block w-full h-auto max-w-[540px]'
            />
            <h3
              className={`heading font-robotoFont text-[30px] text-center ${
                isDarkMode ? "text-dark-mode-white" : "text-gray-700"
              }`}
            >
              Experience the future of hiring.
            </h3>
          </div>
          <div className='hero-lines__container absolute md:bottom-[10%] bottom-[5%] left-0 w-full h-auto'>
            <img
              className='hero-lines w-full h-auto'
              alt='hero-lines'
              src={isDarkMode ? HeroLinesDM : HeroLinesLM}
            />
          </div>
        </div>
      </div>
    </section>
  );
}
