import React, { useContext, useRef, useState } from "react";
import { CalendarContext } from "../../../context/CalendarContext";
import { format } from "date-fns";
import { useDarkMode } from "../../../context/DarkModeContext";
import TimeDropdown from "./TimeDropDown";
import axios from "axios";
import LoadingDots from "./LoadingDots";
const ContactForm = () => {
  const { isDarkMode } = useDarkMode();
  const { currentDate, setReservation } = useContext(CalendarContext);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErros] = useState({
    email: "",
    selectTime: "",
    mobile: "",
  });
  const [selectTime, setSelectTime] = useState("");
  const emailRef = useRef();
  const mobileRef = useRef();
  const validationForm = () => {
    const email = emailRef.current.value || "";
    const mobile = mobileRef.current.value || "";
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const IndNum = /(?:\+?91[-\s]?)?0?\d{10}/;
    let isValid = true;
    if (!emailRegex.test(email)) {
      setErros((prevErrors) => ({
        ...prevErrors,
        email: "Please add an email",
      }));
      isValid = false;
    } else {
      setErros((prevErrors) => ({ ...prevErrors, email: "" }));
    }
    if (IndNum.test(mobile)) {
      setErros((prevErrors) => ({
        ...prevErrors,
        mobile: "",
      }));
    } else {
      setErros((prevErrors) => ({
        ...prevErrors,
        mobile: "Please add an correct mobile",
      }));
      isValid = false;
    }

    if (selectTime === "") {
      setErros((prevErrors) => ({
        ...prevErrors,
        selectTime: "Please select time",
      }));
      isValid = false;
    } else {
      setErros((prevErrors) => ({ ...prevErrors, selectTime: "" }));
    }

    if (isValid) {
      handleSubmit(email, mobile);
    }
  };
  const handleSubmit = async (email, mobile) => {
    setIsLoading(true);
    try {
      const data = {
        email,
        mobile,
        selectTime,
        currentDate,
      };
      const res = await axios.post("api", data);
      setReservation({
        status: "success",
        message: "Your meeting request has been successfully reserved.",
      });
      setIsLoading(false);
    } catch (error) {
      console.log(error.message);
      setReservation({
        status: "failure",
        message: "Failed to reserve meeting. Please try again later.",
      });
      setIsLoading(false);
    }
  };
  const handleTimeChange = (time) => {
    setSelectTime(time);
  };
  const handleInput = () => {
    const inputValue = mobileRef.current.value;
    const filteredValue = inputValue.replace(/[^0-9+]/g, "");
    if (inputValue !== filteredValue) {
      mobileRef.current.value = filteredValue;
    }
  };
  const inputDarkModeClass = isDarkMode
    ? "bg-[#0B2045] text-white placeholder:text-white"
    : "";
  const buttonDarkModeClass = isDarkMode ? "bg-btn-blue" : "bg-main-blue";
  const textClassDark = isDarkMode ? "text-black" : "";
  return (
    <div
      className={`flex flex-col p-3 xl:px-8 max-w-full max-lg:w-full ${
        !isDarkMode ? "bg-white" : "bg-[#0B2045] text-white"
      } ${textClassDark} rounded-2xl shadow-md w-2/5`}
    >
      <div className={`p-2`}>
        <h1 className="text-lg text-center leading-6 md:text-3xl">
          Select Your Time Slot
        </h1>
      </div>
      <div className="flex flex-col">
        <div>
          <div className="flex max-md:flex-col gap-x-4 mt-3 max-sm:gap-4 text-xl">
            <div className="flex flex-col max-lg:w-full w-full">
              <label htmlFor="" className="font-semibold text-xl">
                Selected Day:
              </label>
              <input
                type="text"
                className={`border border-solid lg:w-full max-lg:w-full text-md px-2 py-1 rounded-lg ${inputDarkModeClass}`}
                placeholder="DD/MM/YYYY"
                value={format(currentDate, "dd MMMM yyyy")}
                readOnly
              />
            </div>
            <div className="flex flex-col max-lg:w-full w-full">
              <label htmlFor="" className="font-semibold text-xl">
                Select Time<span className="text-[#FF0000]">*</span>:
              </label>
              <TimeDropdown onChange={handleTimeChange} />
              <div className="min-h-[15px]">
                <p className="text-[#FF0000] text-xs max-h-[15px] overflow-hidden text-ellipsis whitespace-nowrap">
                  {errors.selectTime}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="flex gap-4 flex-wrap flex-col text-xl">
          <div className="flex flex-col max-lg:w-full">
            <label htmlFor="" className="font-semibold">
              Email<span className="text-[#FF0000]">*</span>:
            </label>
            <input
              type="text"
              className={`border border-solid rounded-lg ${inputDarkModeClass} px-2 py-1 max-lg:w-full placeholder:opacity-50`}
              placeholder="example.com@gmail.com"
              ref={emailRef}
            />
            <div className="min-h-[20px]">
              <p className="text-[#FF0000] text-xs max-h-[15px] overflow-hidden text-ellipsis whitespace-nowrap">
                {errors.email}
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-col max-lg:w-full text-xl">
          <label htmlFor="" className="font-semibold">
            Mobile<span className="text-[#FF0000]">*</span>:
          </label>
          <input
            inputMode="tel"
            pattern="[+0-9]*"
            className={`border border-solid rounded-lg ${inputDarkModeClass} px-2 py-1 max-lg:w-full placeholder:opacity-50`}
            placeholder="+91 1234567890"
            ref={mobileRef}
            onInput={handleInput}
          />
          <div className="min-h-[20px]">
            <p className="text-[#FF0000] text-xs max-h-[15px] overflow-hidden text-ellipsis whitespace-nowrap">
              {errors.mobile}
            </p>
          </div>
        </div>
      </div>
      <div className="flex justify-center m-2">
        <button
          onClick={validationForm}
          className={`rounded-full ${
            isDarkMode
              ? "text-dark-mode-white bg-dark-mode-mint"
              : "text-white bg-second-blue"
          } px-12 py-2 shadow-xl`}
        >
          {isLoading ? <LoadingDots /> : "Connect"}
        </button>
      </div>
    </div>
  );
};
export default ContactForm;
