import React, { useContext } from "react";

import checked from "../assets/images/PricingImages/check-mark-icon-sign-png.webp";
import errorImage from "../assets/images/PricingImages/errorImage.png";
import { useDarkMode } from "../../../context/DarkModeContext";
import { CalendarContext } from "../../../context/CalendarContext";
const ResponseCard = () => {
  const { isDarkMode } = useDarkMode();
  const { reservation } = useContext(CalendarContext);

  const textClass = isDarkMode ? "text-white" : "text-gray-700";

  const icon =
    reservation.status === "success"
      ? checked
      : reservation.status === "failure"
      ? errorImage
      : null;

  const status =
    reservation.status === "success"
      ? "Thank You!"
      : reservation.status === "failure"
      ? "Something went wrong!"
      : "";

  return (
    <div className="flex flex-col items-center gap-3 duration-500">
      <img src={icon} alt={reservation.status} className="max-w-60" />
      <h1 className="text-4xl font-semibold">{status}</h1>
      <p className="text-xl font-semibold">{reservation.message}</p>
      {reservation.status === "success" && (
        <p className={`text-md ${textClass}`}>
          You will find the meeting link in the email we've sent to you.
        </p>
      )}
    </div>
  );
};

export default ResponseCard;
