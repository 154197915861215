import AboutMissionLM from "../assets/images/AboutImages/about-mission-LM.png";
import AboutMissionDM from "../assets/images/AboutImages/about-mission-DM.png";
import { useDarkMode } from "./../../../context/DarkModeContext";
export default function AboutMission() {
  const { isDarkMode } = useDarkMode();
  return (
    <div className='wrapper mission-content flex md:gap-x-16 gap-0 medium-large:flex-row-reverse flex-col justify-center medium-large:justify-between'>
      <div className='mission-content__text text-left leading-8 py-5 medium-large:py-0 max-w-full md:max-w-[565px] self-center'>
        <h3
          className={`md:text-[32px] text-center md:text-left text-[24px] font-bold mb-6 leading-10 ${
            isDarkMode ? "text-dark-mode-mint" : "text-second-blue"
          }`}
        >
          Our Mission
        </h3>
        <h4
          className={`md:text-[26px] px-2 md:px-0 text-[18px] md:leading-8 leading-6 ${
            isDarkMode ? "text-dark-mode-white" : "text-black"
          }`}
        >
          At Bias Zero: We focus on revolutionizing hiring with tech for fair,
          efficient recruitment. Dive in for diverse, inclusive, and innovative
          workforce solutions! Explore the future of hiring today and transform
          your recruitment process!
        </h4>
      </div>
      <div className='mission-content__image flex self-center'>
        <img
          className='block md:max-w-[600px] very-small:max-w-[400px] ultra-small:max-w-[300px]'
          src={isDarkMode ? AboutMissionDM : AboutMissionLM}
          alt='mission-interview'
        />
      </div>
    </div>
  );
}
