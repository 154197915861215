import React, { useContext, useState } from "react";

import Calendar from "./calendar";
import ContactForm from "./ContactForm";
import InvestorsCard from "./InvestorsCard";

import { useDarkMode } from "../../../context/DarkModeContext";
import LazyLoad from "react-lazyload";
import {
  clientData,
  investorsData,
  socialMediaData,
  socialMediaDataDark,
} from "./assets/data";
import ClientCard from "./ClientCard";
import { CalendarContext } from "../../../context/CalendarContext";
import ResponseCard from "./ResponseCard";
import { Link } from "react-router-dom";

const Contact = () => {
  const [showMore, setShowMore] = useState(false);
  const { isDarkMode } = useDarkMode();
  const { reservation } = useContext(CalendarContext);

  const backgroundClass = isDarkMode
    ? "bg-dark-mode-navy text-dark-mode-white"
    : "bg-dark-mode-white";
  const imagesClassDarkMode = isDarkMode && "";
  const investorButtonClass = isDarkMode ? "" : "border-black";
  const bgClass = isDarkMode ? "bg-[#193b76]" : "bg-second-blue";

  const socialDarkModeIcons = isDarkMode
    ? socialMediaDataDark
    : socialMediaData;

  return (
    <section className={`${backgroundClass}`}>
      <div
        className={`max-w-screen-4xl flex flex-col gap-2 m-auto duration-500 py-4 xl:py-8 wrapper-l`}
        id='contact'
      >
        <div className='py-10'>
          <h1
            className={`font-bold md:text-[34px] ultra-small:text-[30px] medium-large:text-[44px] text-center ${
              isDarkMode ? "text-dark-mode-mint" : "text-second-blue"
            }`}
          >
            Know Us Better
          </h1>
          <p
            className={`md:text-[22px] ultra-small:text-[18px] medium-large:text-[28px] text-center ${
              isDarkMode ? "text-dark-mode-white" : "text-black"
            }`}
          >
            Connect with us!
          </p>
        </div>
        <div
          className={`flex lg:gap-20 gap-6 max-md:flex-col justify-center ${bgClass} max-md:px-2 max-md:py-4 px-8 py-10 rounded-xl shadow-xl`}
        >
          {reservation.status === "initial" ? (
            <>
              <div
                className={`max-lg:w-full lg:w-6/12 xl:w-2/5 flex flex-col gap-2 ${
                  !isDarkMode
                    ? "bg-dark-mode-white"
                    : "bg-dark-mode-navy text-white"
                } rounded-2xl shadow-md`}
              >
                <Calendar />
              </div>
              <ContactForm />
            </>
          ) : (
            <ResponseCard />
          )}
        </div>
        <div className='max-w-screen-4xl mx-auto my-20 grid max-sm:flex max-sm:flex-col'>
          <h2
            className={`py-10 font-bold md:text-[34px] ultra-small:text-[30px] medium-large:text-[44px] text-center ${
              isDarkMode ? "text-dark-mode-mint" : "text-second-blue"
            }`}
          >
            Social media
          </h2>
          <div className='flex md:flex-row flex-col gap-2 flex-wrap'>
            {socialDarkModeIcons.map((item) => (
              <div key={item.id} className='flex items-center cursor-pointer'>
                <Link
                  href='/'
                  className='text-lg flex gap-x-2 link-hover-underline'
                >
                  <LazyLoad>
                    <img src={item.icon} className='block w-6 h-6' alt='logo' />
                  </LazyLoad>
                  {item.email}
                </Link>
              </div>
            ))}
          </div>
        </div>
        {/* <h2 className="text-4xl font-bold mb-4">Investors</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10 md:mx-5">
          {investorsData.slice(0, 3).map((item) => {
            return <InvestorsCard key={item.id} />;
          })}
        </div>
        {showMore && (
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10 md:mx-5">
            {investorsData.slice(3).map((item) => {
              return <InvestorsCard key={item.id} />;
            })}
          </div>
        )}
        {!showMore ? (
          <div className="flex justify-center">
            <button
              className={`border-2 ${investorButtonClass} border-solid rounded-3xl px-14 py-1 font-bold`}
              onClick={() => setShowMore(!showMore)}
            >
              See More
            </button>
          </div>
        ) : (
          <div className="flex justify-center">
            <button
              className={`border-2 ${investorButtonClass} border-solid rounded-3xl px-14 py-1 font-bold`}
              onClick={() => setShowMore(!showMore)}
            >
              Show Less
            </button>
          </div>
        )} */}
        <div className='md:pt-20 pt-10'>
          <h1
            className={`py-10 font-bold md:text-[34px] ultra-small:text-[30px] medium-large:text-[44px] text-center ${
              isDarkMode ? "text-dark-mode-mint" : "text-second-blue"
            }`}
          >
            Our Clientele
          </h1>
          <div className='grid grid-cols-2 gap-4 md:grid-cols-5'>
            {clientData.map((item) => (
              <ClientCard
                key={item.id}
                title={item.text}
                img={item.img}
                id={item.id}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
