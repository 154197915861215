import { useState, useEffect, useRef } from "react";
import { useDarkMode } from "../../../context/DarkModeContext";
import DashboardImageMain from "../assets/images/HeroImages/Generate questions_HS_LM.png";
import DashboardImageMainDM from "../assets/images/HeroImages/Generate questions_HS_DM.jpg";
import BiasZeroIntroVideo from "../assets/videos/bias-zero-introduction-upscaled.webm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
export default function HeroTitle() {
  const { isDarkMode } = useDarkMode();
  const words = ["Unbiased", "Unrestricted", "Unrivaled"];
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [isFadingOut, setIsFadingOut] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const videoRef = useRef(null);
  const modalContentRef = useRef(null);
  useEffect(() => {
    const intervalId = setInterval(() => {
      setIsFadingOut(true);
      setTimeout(() => {
        setCurrentWordIndex((prevIndex) => (prevIndex + 1) % words.length);
        setIsFadingOut(false);
      }, 1000);
    }, 5000);
    return () => clearInterval(intervalId);
  }, [words.length]);
  const getColorClass = (index) => {
    switch (index) {
      case 1:
        return "text-[#EE4335]";
      case 2:
        return "text-[#FDBC25]";
      default:
        return "";
    }
  };
  const handleLearnMoreClick = (event) => {
    event.preventDefault();
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
    if (videoRef.current) {
      videoRef.current.pause();
      videoRef.current.currentTime = 0;
    }
  };
  const handleClickOutside = (event) => {
    if (
      modalContentRef.current &&
      !modalContentRef.current.contains(event.target)
    ) {
      closeModal();
    }
  };
  useEffect(() => {
    if (isModalOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isModalOpen]);
  return (
    <div className='hero-text__content flex flex-col gap-y-10 mb-10 text-center md:text-left'>
      <h1 className='hero-title z-30 flex font-outfitFont relative justify-center md:justify-start mb-6 font-bold'>
        <span
          className={`md:text-8xl text-6xl flex flex-col gap-2 ${
            isDarkMode ? "text-dark-mode-white" : "text-dark-mode-navy"
          }`}
        >
          Bias Zero
          <span
            className={`md:text-7xl text-4xl text-center md:text-left ${
              isFadingOut ? "animate-fadeOut" : "animate-fadeIn"
            } ${getColorClass(currentWordIndex)}`}
          >
            {words[currentWordIndex]}
          </span>
        </span>
      </h1>
      <div className='dahboard-text animate-fadeIn flex flex-col gap-y-10 relative z-20'>
        <h2
          className={`heading font-robotoFont font-bold md:text-[26px] text-[22px]  ${
            isDarkMode
              ? "text-dark-mode-white"
              : "md:text-gray-700 ultra-small:text-black"
          }`}
        >
          Revolutionizes hiring with AI, delivering unbiased and efficient
          recruitment.
        </h2>
        <div className='hero-text__buttons flex medium-small:flex-row flex-col medium-small:gap-y-0 gap-y-4 justify-between'>
          <a
            href='/'
            target='_blank'
            rel='noopener'
            className={`try-demo-btn medium-small:w-fit w-full self-center ${
              isDarkMode
                ? "md:bg-dark-mode-mint text-dark-mode-white bg-dark-mode-navy border-dark-mode-white"
                : "bg-second-blue text-white"
            } z-20 py-2 px-16 border-2 border-dark-mode-navy border-solid rounded-xl font-bold text-lg cursor-pointer transition-all duration-300 ease-in-out hover:bg-[#051229]`}
          >
            Try Demo
          </a>
          <a
            href='/'
            target='_blank'
            rel='noopener'
            onClick={handleLearnMoreClick}
            className={`try-demo-btn medium-small:w-fit w-full self-center ${
              isDarkMode
                ? "bg-dark-mode-navy text-dark-mode-white border-dark-mode-white py-[8px]"
                : "bg-white border-dark-mode-navy text-dark-mode-navy hover:text-white"
            } z-20 py-2 px-14 border-solid border-2 rounded-xl font-bold text-lg cursor-pointer transition-all duration-300 ease-in-out hover:bg-[#051229]`}
          >
            Learn more
          </a>
        </div>
      </div>
      <div className='relative z-20 flex-col gap-y-6 small-large:flex hidden'>
        <img
          src={isDarkMode ? DashboardImageMainDM : DashboardImageMain}
          alt='dashboard'
          className=' dashboard-img  animate-dashSlideIn block max-w-full h-auto'
        />
      </div>
      {isModalOpen && (
        <div className='modal-overlay px-3 fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-[999]'>
          <div
            className={`modal-content p-6 rounded shadow-lg z-40 relative ${
              isDarkMode ? "bg-dark-mode-navy" : "bg-white"
            }`}
            ref={modalContentRef}
          >
            <button className='absolute top-0 right-0 p-1' onClick={closeModal}>
              <i className='block m-0 text-[20px]'>
                <FontAwesomeIcon icon={faCircleXmark} className='block m-0' />
              </i>
            </button>
            <div className='max-w-[800px]'>
              <video ref={videoRef} autoPlay controls disablePictureInPicture>
                <source src={BiasZeroIntroVideo} type='video/mp4' />
              </video>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
