import React from "react";
import { useDarkMode } from "../../../context/DarkModeContext";
import LazyLoad from "react-lazyload";
import zappi_720 from "./assets/images/dn_720.jpg";
const ClientCard = ({ img, title, id }) => {
  const { isDarkMode } = useDarkMode();

  const isDarkModeClass = isDarkMode ? "text-white" : "text-black";

  const imageSrc = isDarkMode && id === 10 ? zappi_720 : img;

  return (
    <div
      className={`p-4 border rounded flex flex-col items-center justify-center gap-4 ${isDarkModeClass}`}
    >
      <LazyLoad>
        <img src={imageSrc} alt={title} className="max-w-32" />
      </LazyLoad>
      <p>{title}</p>
    </div>
  );
};

export default ClientCard;
